!function () {
  // segment integrates inspectlet. inspectlet is loaded after segment's analytics.js are processed.
  // inspectlet.js contains jquery@1.12 which does a detection whether the current browser has support for
  // a focusin event. All modern browsers already have it. Unfortunately, the way how jQuery does the detection,
  // triggers a CSP violation because jQuery does this:
  // https://github.com/jquery/jquery/blob/1.12-stable/src/event/support.js#L17
  // 1. check onfocusin is in a window
  // 2. if not, create a div and set attribute onfocusin="t" on it
  // 3. check attribute exists and it wasn't removed (browser which doesn't support would remove it)
  //
  // ^^ #2 is considered as inline script on Firefox 78 and Edge 18. We do not allow to run inline scripts in our
  // CSP, you need to either add nonce or hash. We don't have control of adding nonce, because the script is 
  // loaded from segment's script. unsafe-hash is not supported by Firefox, nor Edge.
  //
  // The solution to this would be update jQuery in inspectlet to >2.0, but we have to wait on the maintainers to do so.
  // Another solution would be serving inspectlet from our domain and patch the jQuery in it. We would loose 
  // the CDN and caching of the script after.
  // Another option is to trick jQuery's detection, and always make step #1 in the detection described above to pass.
  // And that's exactly what we are doing here:
  if (!('onfocusin' in window)) {
    window.onfocusin = true; // set it to any value and make the jQuery detection pass early.
  }

  // see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
  // for reference
  var analytics = window.analytics = window.analytics || [];
  if (analytics.initialize) return;
  
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }

  analytics.invoked = true;
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ];

  analytics.factory = function(method){
    return function(){
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  analytics.load = function(key, options){
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

    var first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };

  analytics._writeKey = segmentKey;
  analytics.SNIPPET_VERSION = '4.13.2';

  if (!segmentEnabled) {
    return;
  }

  analytics.load(segmentKey, {
    integrations: {
      'Inspectlet': isInspectletConsentAccepted,
    },
    disableClientPersistence: true,
    user: {
      persist: false,
    },
    group: {
      persist: false,
    },
  });

  analytics.page();

  // custom identity
  var INDIVIDUAL_TYPE = 0;
  var COMPANY_TYPE = 1;
  if (customerType === COMPANY_TYPE) {
    customerType = 'Corporate';
  } else if (customerType === INDIVIDUAL_TYPE) {
    customerType = 'Private';
  }

  if (userIdentifier) {
    analytics.identify(userIdentifier, {
      dealer_email: dealer_email,
      brand: brandName,
      environment: environment,
      company: client_name,
      accountId: clientIdentifier,
      contactId: userIdentifier,
      language: language,
      customerType: customerType,
      officeCountry: officeCountry
    });
  } else {
    analytics.identify('0');
  }
}();
