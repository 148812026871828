var forceReload = false;

window.addEventListener('storage', function onStorage(e) {
  var storageCleared = e.key === null;
  var clientIdentifierChanged = e.key === 'clientIdentifier' && e.newValue !== clientIdentifier;
  if (storageCleared || clientIdentifierChanged) {
    // Every time the client is changed, all the tabs need to be reloaded in order sync the data to display
    forceReload = true; // Needed for reloading some pages without asking like 'New trade'
    window.location.reload();
  }
});

if (typeof clientIdentifier !== 'undefined') {
  localStorage.setItem('clientIdentifier', clientIdentifier);
}
