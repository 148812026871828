/* global Huha */

!function (Huha) {
  var huha = new Huha({
    trackOnGoogleAnalytics: true,
    trackOnIntercom: window.enabledIntercom || false,
    trackOnSegment: true
  });

  function existHuhaTask(huhaTask) {
    return huhaTask && Object.keys(huhaTask).length > 0;
  }

  /**
   * Increments the effort of the given huha task
   * @param huhaTask {HuhaTask}
   */
  function addHuhaInteraction(huhaTask) {
    if (existHuhaTask(huhaTask)) {
      huhaTask.addInteraction();
    }
  }

  /**
   * Increments the error of the given huha task
   * @param huhaTask {HuhaTask}
   */
  function addHuhaError(huhaTask) {
    if (existHuhaTask(huhaTask)) {
      huhaTask.addError();
    }
  }

  /**
   * Completes the given huha task
   * @param huhaTask {HuhaTask}
   */
  function completeHuhaTask(huhaTask) {
    if (existHuhaTask(huhaTask)) {
      huhaTask.complete();
    }
  }

  /**
   * Abandons the given huha task
   * @param huhaTask {HuhaTask}
   */
  function abandonHuhaTask(huhaTask) {
    if (existHuhaTask(huhaTask)) {
      huhaTask.abandon();
    }
  }

  /**
   * Creates a huha event with the given details
   * @param object {string} Name of the object that is being manipulated during the event
   * @param action {string} Name of the action that is being executed in the object during the event
   * @param category {string} Name of the the section of this event, so it can be grouped as categories
   * @param value {string} Value of the action done to the object
   * @param groupId {string} Identifier of the group this event is linked to
   */
  function trackHuhaEvent(object, action, category, value, task, groupId) {
    // Defaults
    object = object || '';
    action = action || '';
    category = category || '';
    value = value || '';
    task = task || '';
    groupId = groupId || '';

    var event = action + ' ' + object;
    huha.createEvent(event, object, action, category, value, task, groupId);
  }

  window.huha = huha;
  window.addHuhaInteraction = addHuhaInteraction;
  window.addHuhaError = addHuhaError;
  window.completeHuhaTask = completeHuhaTask;
  window.abandonHuhaTask = abandonHuhaTask;
  window.trackHuhaEvent = trackHuhaEvent;
}(Huha);
